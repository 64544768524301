import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import Helper from "../../util/helper";
function* getInvoiceList(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingApi/invoice?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      let invoiceList = response.body.data;
      const studentIds = Helper.getStudentList()?.map(st => st?.id)?.filter(st => st);
      invoiceList = invoiceList.filter(inv => studentIds.includes(inv.studentId));
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, invoiceList }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch invoice list", err);
  }
}

function* getStudentInvoiceList(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingApi/invoice/student?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, studentInvoiceList: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch student invoice list", err);
  }
}

function* getStudentInvoiceListFromStudentId(request) {
  try {
    const { firebase, startDate, endDate, studentId } = request.payload;
    let endpoint = `bookingApi/invoice/student/${studentId}?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, studentIdInvoiceList: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch student invoices", err);
  }
}

function* getTransactions(request) {
  try {
    const { firebase, startDate, endDate } = request.payload;
    let endpoint = `bookingApi/invoice/transaction?startDate=${startDate}&endDate=${endDate}`;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      let transactionList = response.body.data;
      const studentIds = Helper.getStudentList()?.map(st => st?.id)?.filter(st => st);
      transactionList = transactionList.filter(trans => studentIds.includes(trans.studentId));
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, transactionList }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch transactions", err);
  }
}
function* getAllTaxes(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/tax`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, taxes: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch taxes", err);
  }
}

function* getAllDiscounts(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/discount`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, discounts: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch discounts", err);
  }
}

function* getAllPackages(request) {
  try {
    const { firebase } = request.payload;
    let endpoint = `bookingApi/package/all/names?teacherId=` + firebase.user.id;
    let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, packages: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch discounts", err);
  }
}

function* getPricePackByPackageId(request) {
  try {
    const { firebase, packageId } = request.payload;
    let endpoint = `bookingApi/package/${packageId}/classPack`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, pricingPackByPackageId: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch discounts", err);
  }
}

function* getInvoiceById(request) {
  try {
    const { firebase, invoiceId } = request.payload;
    let endpoint = `bookingApi/invoice/${invoiceId}`;
    let response = yield call(callApi, firebase, "get", endpoint, {});
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, invoiceLoader: false, invoice: response.body.data }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to fetch invoice", err);
  }
}

function* createInvoice(request) {
  try {
    const { firebase, formData } = request.payload;
    let endpoint = `bookingApi/invoice`;
    let response = yield call(callApi, firebase, "post", endpoint, formData);
    if (response.status == 200) {
      notification("success", formatMsg("success.invoiceCreated"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "CREATE_INVOICE_SUCCESS" }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to create invoice", err);
  }
}

function* editInvoice(request) {
  try {
    const { firebase, formData } = request.payload;
    let endpoint = `bookingApi/invoice/${formData.id}`;
    let response = yield call(callApi, firebase, "post", endpoint, formData);
    if (response.status == 200) {
      notification("success", formatMsg("success.invoiceEdited"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "CREATE_INVOICE_SUCCESS" }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to create invoice", err);
  }
}

function* createBooking(request) {
  try {
    const { firebase, requestPayload } = request.payload;
    let endpoint = `bookingApi/booking`;
    let response = yield call(callApi, firebase, "post", endpoint, requestPayload);
    if (response.status == 200) {
      notification("success", formatMsg("success.bookingCreated"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, operationType: "CREATE_INVOICE_SUCCESS" }
      });
    }
    else {
      notification("error", response?.body?.message || formatMsg("error.occured"))
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to create invoice", err);
  }
}

function* addRefund(request) {
  try {
    const { firebase, requestPayload, invoiceId } = request.payload;
    let endpoint = `bookingApi/refund/${invoiceId}`;
    let response = yield call(callApi, firebase, "post", endpoint, { ...requestPayload });
    if (response.status == 200) {
      notification("success", formatMsg("success.refundAdded"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, refetchInvoice: invoiceId }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to create invoice", err);
  }
}
function* addPayment(request) {
  try {
    const { firebase, requestPayload, invoiceId } = request.payload;
    let endpoint = `bookingApi/payment/${invoiceId}`;
    let response = yield call(callApi, firebase, "post", endpoint, { ...requestPayload });
    if (response.status == 200) {
      notification("success", formatMsg("success.paymentAdded"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, refetchInvoice: invoiceId }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to create invoice", err);
  }
}

function* deleteRefund(request) {
  try {
    const { firebase, requestPayload, invoiceId } = request.payload;
    let endpoint = `bookingApi/refund/${invoiceId}`;
    let response = yield call(callApi, firebase, "delete", endpoint, { ...requestPayload });
    if (response.status == 200) {
      notification("success", formatMsg("success.refundAdded"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, refetchInvoice: invoiceId }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to deleteRefund", err);
  }
}

function* deletePayment(request) {
  try {
    const { firebase, requestPayload, invoiceId } = request.payload;
    let endpoint = `bookingApi/payment/${invoiceId}`;
    let response = yield call(callApi, firebase, "delete", endpoint, { ...requestPayload });
    if (response.status == 200) {
      notification("success", formatMsg("success.paymentAdded"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, refetchInvoice: invoiceId }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to deletePayment", err);
  }
}

function* sendFeeReminder(request) {
  try {
    const { firebase, requestPayload } = request.payload;
    let endpoint = `bookingApi/invoice/reminder`;
    let response = yield call(callApi, firebase, "post", endpoint, requestPayload);
    if (response.status == 200) {
      notification("success", formatMsg("success.feeReminder"))
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to send fee reminder", err);
  }
}

function* invoicePDF(request) {
  try {
    const { firebase, requestPayload } = request.payload;
    let endpoint = `bookingApi/invoice/pdf`;
    let response = yield call(callApi, firebase, "post", endpoint, requestPayload);
    if (response.status == 200) {
      yield put({
        type: actions.INVOICE_SAGA_SUCCESS,
        payload: { isLoading: false, invoicePDF: response.body.data, operationType: "INVOICE_PDF_FETCHED", refetchInvoice: requestPayload.id }
      });
    }
    else {
      yield put({ type: actions.INVOICE_SAGA_FAILED });
    }
  } catch (err) {
    Helper.notifyBugsnag(err, true);
    yield put({ type: actions.INVOICE_SAGA_FAILED });
    console.log("failed to send fee reminder", err);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_INVOICE_LIST, getInvoiceList);
  yield takeLatest(actions.GET_STUDENT_INVOICE_LIST, getStudentInvoiceList);
  yield takeLatest(actions.GET_TRANSACTION_LIST, getTransactions);
  yield takeLatest(actions.GET_STUDENT_INVOICE_LIST_BY_STUDENT_ID, getStudentInvoiceListFromStudentId);
  yield takeLatest(actions.GET_ALL_TAXES, getAllTaxes);
  yield takeLatest(actions.GET_ALL_DISCOUNTS, getAllDiscounts);
  yield takeLatest(actions.GET_ALL_PACKAGES, getAllPackages);
  yield takeLatest(actions.CREATE_INVOICE, createInvoice);
  yield takeLatest(actions.EDIT_INVOICE, editInvoice);
  yield takeLatest(actions.CREATE_BOOKING, createBooking);
  yield takeLatest(actions.ADD_REFUND, addRefund);
  yield takeLatest(actions.ADD_PAYMENT, addPayment);
  yield takeLatest(actions.DELETE_REFUND, deleteRefund);
  yield takeLatest(actions.DELETE_PAYMENT, deletePayment);
  yield takeLatest(actions.SEND_FEE_REMINDER, sendFeeReminder);
  yield takeLatest(actions.INVOICE_PDF, invoicePDF);
  yield takeLatest(actions.GET_INVOICE_BY_ID, getInvoiceById);
  yield takeLatest(actions.GET_PRICE_PACK_BY_PACKAGE_ID, getPricePackByPackageId);
}