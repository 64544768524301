import { combineReducers } from 'redux';
import login from './login/reducer';
import packageReducer from './package/reducer'
import bookingActivity from './bookingActivity/reducer';
import students from "./students/reducer";
import staffs from "./staffs/reducer";
import invoiceReducer from "./invoice/reducer";
import calendarReducer from "./calendar/reducer";
import parentReducer from "./parent/reducer";
import settingReducer from "./setting/reducer";
import notificationReducer from "./notification/reducer";
import dashboardReducer from "./dashboard/reducer";
import multicenterReducer from "./multicenter/reducer"; 
import reportsReducer from './reports/reducer'; 


const rootReducer = combineReducers({
    login,
    packageReducer,
    bookingActivity,
    students,
    staffs,
    invoiceReducer,
    calendarReducer,
    parentReducer,
    settingReducer,
    notificationReducer,
    dashboardReducer,
    multicenterReducer, 
    reportsReducer 
});


export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;