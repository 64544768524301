import "firebase/compat/storage";
import { call, takeLatest, put } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import Helper from "../../util/helper";

function* updateStaffSaga(request) {
    try {
        const { firebase, values } = request.payload;
        let storagePath = firebase.sbp + "/media/images/";
        if (values.profileImageUrl && typeof values.profileImageUrl !== 'string') {
            let photourl = yield call(Helper.getAttachedMediaPath, storagePath, values.profileImageUrl, firebase);
            if (photourl) {
                values.profileImageUrl = photourl[0].path
            }
        }
        values.newBranches = [
            {
                "dbName": firebase.schoolConfig.centerId,
                "name": firebase.schoolConfig.branchPath
            }
        ]
        if (values.centers) values.centers = [...values.centers.map(center => center.id ? center.id : center)]
        let endpoint = "bookingApi/teacher"
        if (values.id) {
            endpoint += "/" + values.id
        }
        let response = yield call(callApi, firebase, "post", endpoint, values);
        if (response.status == 200) {
            notification("success", formatMsg(values.id ? "updateStaffSuccess" : "saveStaffSuccess"));
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { operationType: "STAFF_UPDATE", isLoading: false, updatedStaff: response.body.data?.data } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STAFF_REQUEST_FAILED });
        console.log("failed to save staff ", err);
    }
}

function* getAllStaffPackagesAndActivities(request) {
    try {
        const { firebase, } = request.payload;
        let endpoint = "bookingApi/teacher/all/packagesAndActivities"
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            let staffPackagesAndActivities = response.body.data?.teachersPackagesAndActivities || [];
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { staffPackagesAndActivities, staffPackagesAndActivitiesLoading: false } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STAFF_REQUEST_FAILED });
        console.log("failed get all staff packages and activities ", err);
    }
}

function* getAllPackages(request) {
    try {
        const { firebase, teacherId } = request.payload;
        let endpoint = `bookingApi/teacher/${teacherId}/packages`
        let response = yield call(callApi, firebase, "get", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { isLoading: false, operationType: "FETCH_PACKAGES", packages: response.body.data } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.STAFF_REQUEST_FAILED
        });
        console.log("failed to fetch packages", err);
    }
}

function* deleteStaff(request) {
    try {
        const { firebase, id } = request.payload;
        let endpoint = "bookingApi/teacher/" + id
        let response = yield call(callApi, firebase, "delete", endpoint, {});
        if (response.status == 200) {
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { isLoading: false } });
            notification("success", formatMsg('deleteStaffSuccess'));
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STAFF_REQUEST_FAILED });
        console.log("failed to delete staff", err);
    }
}

function* fetchStaffSchedules(request) {
    try {
        const { firebase, id, reqObj } = request.payload;
        let endpoint = `bookingApi/teacher/${id}/bookingSchedules` + "?startDate=" + reqObj.startDate + "&endDate=" + reqObj.endDate
        let response = yield call(callApi, firebase, "get", endpoint, {}, false, false, true);
        if (response.status == 200) {
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { operationType: "FETCH_STAFF_SCHEDULES", isLoading: false, staffSchedules: response.body.data } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STAFF_REQUEST_FAILED });
        console.log("failed to fetch staff schedules", err);
    }
}

function* sendInviteToTeachers(request) {
    try {
        const { firebase, list } = request.payload;
        let endpoint = `bookingApi/invite/staff`
        let response = yield call(callApi, firebase, "post", endpoint, { list });
        if (response.status == 200) {
            notification("success", formatMsg(response.body.message));
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { operationType: "SENT_STAFF_INVITE", isLoading: false } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STAFF_REQUEST_FAILED });
        console.log("failed to send staff invite", err);
    }
}

function* setActiveInactiveStaff(request) {
    try {
        const { firebase, teacherId } = request.payload;
        let endpoint = `bookingApi/teacher/${teacherId}/activeInactive`
        let response = yield call(callApi, firebase, "post", endpoint, {});
        if (response.status == 200) {
            notification("success", formatMsg(response.body.message));
            yield put({ type: actions.STAFF_REQ_SUCCESS, payload: { operationType: "ACTIVE_INACTIVE_TEACHER", isLoading: false } });
        }
        else {
            notification("error", response?.body?.data?.message || formatMsg("error.occured"))
            yield put({ type: actions.STAFF_REQUEST_FAILED });
        }
    } catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.STAFF_REQUEST_FAILED });
        console.log("failed to send staff invite", err);
    }
}


export default function* rootSaga() {
    yield takeLatest(actions.STAFF_PACKAGES_AND_ACTIVITIES, getAllStaffPackagesAndActivities);
    yield takeLatest(actions.UPDATE_STAFF, updateStaffSaga);
    yield takeLatest(actions.DELETE_STAFF, deleteStaff);
    yield takeLatest(actions.FETCH_STAFF_SCHEDULES, fetchStaffSchedules);
    yield takeLatest(actions.GET_ALL_STAFF_PACKAGES, getAllPackages);
    yield takeLatest(actions.SEND_INVITE_TO_STAFFS, sendInviteToTeachers);
    yield takeLatest(actions.SET_ACTIVE_INACTIVE_STAFF, setActiveInactiveStaff);

}