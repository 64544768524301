import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false, 
    allCentersList: [], 
    lowercasedCenterNamesSet: new Set(), 
    centerIdToCenterObjMap: new Map(), 
    centersListOfCurrPackageInView: [], 
    operationType: ''
}

export const packageSlice = createSlice({
    name: 'multicenter',
    initialState: { ...initialState },
    reducers: {
        getAllCentersList: (state, action) => {
            state.isLoading = true; 
        },
        saveCenter: (state, action) => {
            state.isLoading = true; 
        },
        deleteCenter: (state, action) => {
            state.isLoading = true; 
        }, 
        getCentersListOfSinglePackage: (state, action) => {
            state.isLoading = true; 
        },
        multiCenterSagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        multiCenterSagaFailed: (state, action) => {
            state.operationType = ''; 
            state.isLoading = false; 
        },
        resetMultiCenterOperationType: (state, action) => {
            state.operationType = '';
            state.isLoading = false;
        },
        resetMulticenterReducer: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        }
    },
})


export const {
    getAllCentersList, 
    saveCenter, 
    deleteCenter,
    getCentersListOfSinglePackage,
    multiCenterSagaSuccess, 
    multiCenterSagaFailed, 
    resetMultiCenterOperationType, 
    resetMulticenterReducer 
} = packageSlice.actions; 


export const defaultMulticenterReducerState = {
    isLoading: false, 
    allCentersList: [], 
    lowercasedCenterNamesSet: new Set(), 
    centerIdToCenterObjMap: new Map(), 
    centersListOfCurrPackageInView: [], 
    operationType: '' 
}


export default packageSlice.reducer; 