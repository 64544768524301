import { call, put, take, takeLatest } from "redux-saga/effects";
import { callApi } from "../../util/superAgentUtil";
import actions from "../actionType";
import { notification } from "../../util/notification";
import formatMsg from "../../util/formatMsg";
import Helper from "../../util/helper";
import dbRef from "../../firebase-api/dbRef";
import fbRef from "../../libs/fbRef";


function* getAllGeneratedReportsByChannel(actionObj: Record<string, any>) {

    let allGeneratedReportsChannel: any;

    try {
        const { firebase } = actionObj.payload;
        allGeneratedReportsChannel = yield call(dbRef.getEntitiesByChannel, firebase, fbRef.reportRequests, firebase.teacher.id, true, "teacherId");

        while (true) {
            const allGeneratedReports = yield take(allGeneratedReportsChannel);

            allGeneratedReports.sort((a, b) => {
                let sortInt = Helper.sortIntegerValue(a, b, "createdOn");
                return -1 * sortInt;
            });

            yield put({
                type: actions.REPORTS_SAGA_SUCCESS,
                payload: {
                    isLoading: false,
                    allGeneratedReports,
                    allGeneratedReportsChannel
                }
            })
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({
            type: actions.REPORTS_SAGA_FAILED
        });
        console.log("Failed to fetch all generated reports via channel", err);
    }
    finally {
        if (allGeneratedReportsChannel) {
            allGeneratedReportsChannel.close();
        }
    }
}


function* generateReport(actionObj: Record<string, any>) {
    try {

        const { firebase, endpointSuffix, values } = actionObj.payload; 

        const payload = Helper.buildReportPayload(firebase, endpointSuffix, values); 

        let endpoint = `bookingApi${endpointSuffix}`; 
        let response = yield call(callApi, firebase, "post", endpoint, payload); 

        if (response.status == 200) {
            yield put({
                type: actions.REPORTS_SAGA_SUCCESS,
                payload: {
                    isLoading: false,
                    operationType: 'REPORT_GENERATED_SUCCESSFULLY'
                }
            });
        }
        else {
            yield put({ type: actions.REPORTS_SAGA_FAILED });
            notification('error', formatMsg(`oopsSomthingWentWrong`));
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REPORTS_SAGA_FAILED });
        console.log("Failed to generate report", err);
    }
}


function* deleteReport(actionObj: Record<string, any>) {
    try {

        const { firebase, reportId } = actionObj.payload; 

        let endpoint = `bookingApi/reports/${reportId}?teacherId=${firebase.teacher.id}`; 
        let response = yield call(callApi, firebase, "delete", endpoint, {}, null, null, true); 

        if (response.status == 200) {
            yield put({
                type: actions.REPORTS_SAGA_SUCCESS,
                payload: {
                    isLoading: false,
                    operationType: 'REPORT_DELETED_SUCCESSFULLY'
                }
            });
        }
        else {
            yield put({ type: actions.REPORTS_SAGA_FAILED });
            notification('error', formatMsg(`oopsSomthingWentWrong`));
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REPORTS_SAGA_FAILED });
        console.log("Failed to delete report", err);
    }
}



function* clearAllReports(actionObj: Record<string, any>) {
    try {

        const { firebase } = actionObj.payload;

        let endpoint = `bookingApi/reports/clearAll?teacherId=${firebase.teacher.id}`;
        let response = yield call(callApi, firebase, "delete", endpoint, {}, null, null, true);

        if (response.status == 200) {
            yield put({
                type: actions.REPORTS_SAGA_SUCCESS,
                payload: {
                    isLoading: false,
                    operationType: 'ALL_REPORTS_DELETED_SUCCESSFULLY'
                }
            });
        }
        else {
            yield put({ type: actions.REPORTS_SAGA_FAILED });
            notification('error', formatMsg(`oopsSomthingWentWrong`));
        }
    }
    catch (err) {
        Helper.notifyBugsnag(err, true);
        yield put({ type: actions.REPORTS_SAGA_FAILED });
        console.log("Failed to delete all reports", err);
    }
}


export default function* rootSaga() {
    yield takeLatest(actions.GET_ALL_GENERATED_REPORTS_BY_CHANNEL, getAllGeneratedReportsByChannel);
    yield takeLatest(actions.GENERATE_REPORT, generateReport);
    yield takeLatest(actions.DELETE_REPORT, deleteReport);
    yield takeLatest(actions.CLEAR_ALL_REPORTS, clearAllReports);
}