import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoading: false, 
    allGeneratedReports: [], 
    allGeneratedReportsChannel: null,
    operationType: '' 
}

export const packageSlice = createSlice({
    name: 'reports',
    initialState: { ...initialState },
    reducers: {
        getAllGeneratedReportsByChannel: (state, action) => {
            state.isLoading = true; 
        },
        generateReport: (state, action) => {
            state.isLoading = true; 
        },
        deleteReport: (state, action) => {
            state.isLoading = true; 
        }, 
        clearAllReports: (state, action) => {
            state.isLoading = true; 
        }, 
        reportsSagaSuccess: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        },
        reportsSagaFailed: (state, action) => {
            state.operationType = ''; 
            state.isLoading = false; 
        },
        resetReportsReducer: (state, action) => {
            if (action.payload) {
                Object.assign(state, action.payload);
            }
        }
    },
})


export const {
    getAllGeneratedReportsByChannel, 
    generateReport, 
    deleteReport, 
    clearAllReports, 
    reportsSagaSuccess, 
    reportsSagaFailed, 
    resetReportsReducer 
} = packageSlice.actions; 


export const defaultReportsReducerState = { 
    isLoading: false, 
    allGeneratedReports: [], 
    allGeneratedReportsChannel: null,
    operationType: ''
}


export default packageSlice.reducer; 